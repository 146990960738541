<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img class="add" src="@/assets/address.png" />
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img class="right" src="@/assets/breadr.png" />
        <router-link to="/product">产品中心</router-link>
        <img class="right" src="@/assets/breadr.png" />
        <router-link
          :to="{ name: 'ProductDetail', params: { id: $route.params.id } }"
          >{{ info.name }}</router-link
        >
      </div>
      <div class="pro-detail-wrap">
        <div class="detail-con">
          <div class="main">
            <div class="imgs">
              <img
                :src="onImgIndex !== '' ? info.images[onImgIndex] : ''"
                alt=""
              />
              <div>
                <a @click="last" class="last" href="javascript:;">
                  <img src="@/assets/pro_left.png" />
                </a>
                <div>
                  <div>
                    <a
                      v-for="(item, index) in info.images"
                      :key="index + 'img'"
                      :class="{ on: onImgIndex === index }"
                      @click="onImgIndex = index"
                      href="javascript:;"
                    >
                      <img :src="item" />
                    </a>
                  </div>
                </div>
                <a @click="next" class="next" href="javascript:;">
                  <img src="@/assets/pro_right.png" />
                </a>
              </div>
            </div>
            <div class="info">
              <h1 class="name">{{ info.name }}</h1>
              <span class="code">编号: {{ info.no }}</span>
              <div class="money">
                <p>
                  <span class="label">厂家价</span>
                  <span class="now-money">¥ {{ info.price }}</span>
                  <span class="old-money">[{{ info.original_price }}] </span>
                </p>
                <p>
                  <span class="label">优惠活动</span>
                  <span class="c">免费刻字，满500包邮 </span>
                </p>
              </div>
              <div class="others">
                <p>
                  <span class="label">基础运费</span>
                  <span class="c">郑州 至 北京 快递:8.00</span>
                </p>
                <p>
                  <span class="label">库存</span>
                  <span class="c">{{ info.stock_num }}件</span>
                </p>
                <p>
                  <span class="label">服务承诺</span>
                  <span class="c">正品保障 7天退换 1年保修</span>
                </p>
              </div>
              <div class="btns">
                <a class="sc" @click="collection"
                  >收藏商品</a
                >
                <a class="tel" href="javascript:;">电话咨询</a>
              </div>
            </div>
          </div>
          <div class="params">
            <h2 class="h2-title">产品参数</h2>
            <ul v-if="info.params.length">
              <li
                v-for="(item, index) in info.params[0].split('\r\n')"
                :key="'param' + index"
              >
                <p>{{ item }}</p>
              </li>
            </ul>
          </div>
          <div class="content">
            <h2 class="h2-title">产品详情</h2>
            <div class="content-details" v-html="info.content"></div>
          </div>
        </div>
        <div class="about-pros">
          <div class="title">
            <img src="@/assets/rqjx.png" />
          </div>
          <ul class="list">
            <li v-for="item in hots" :key="item.id + 'hot'">
              <router-link
                :to="{ name: 'ProductDetail', params: { id: item.id } }"
              >
                <img :src="item.images[0]" />
                <div>
                  <p class="ellipsis">{{ item.name }}</p>
                  <span>¥{{ item.price }}</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <login
      :show="loginShow"
      @openRegister="registerShow = true"
      @closeLogin="loginShow = false"
    />
    <register
      :show="registerShow"
      @openLogin="loginShow = true"
      @closeRegister="registerShow = false"
    />
  </div>
</template>

<script>
import { getProDetail, getProsHot } from "@/api";
import { mapGetters } from "vuex";
import login from "@/components/Login";
import register from "@/components/Register";
export default {
  name: "ProductDetail",
  components: {
    login,
    register,
  },
  data() {
    return {
      imgs: ["", "", "", "", "", "", ""],
      imgIndex: 0,
      loginShow: false,
      registerShow: false,
      info: {
        images: [],
        params: [],
      },
      onImgIndex: "",
      hots: [],
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    getProDetail({ id: this.$route.params.id })
      .then((res) => {
        if (res.data.code) {
          this.info = res.data.data;
          if (this.info.images.length) {
            this.onImgIndex = 0;
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    getProsHot({ limit: 12 })
      .then((res) => {
        if (res.data.code) {
          this.hots = res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    last() {
      let dom = document.querySelector(
        ".detail-con .main .imgs > div > div > div"
      );
      if (parseFloat(dom.style.left) === 0 || !dom.style.left) {
        return;
      }
      this.imgIndex--;
      let marginRight = parseFloat(
        window.getComputedStyle(
          document.querySelector(".detail-con .main .imgs > div > div > div>a")
        )["margin-right"]
      );
      let width = parseFloat(
        window.getComputedStyle(
          document.querySelector(".detail-con .main .imgs > div > div > div>a")
        )["width"]
      );
      dom.style.left = -this.imgIndex * (width + marginRight) + "px";
    },
    next() {
      let dom = document.querySelector(
        ".detail-con .main .imgs > div > div > div"
      );
      let cha =
        parseFloat(
          window.getComputedStyle(
            document.querySelector(".detail-con .main .imgs > div > div > div")
          )["width"]
        ) -
        parseFloat(
          window.getComputedStyle(
            document.querySelector(".detail-con .main .imgs > div > div")
          )["width"]
        );

      if (cha <= 0 || -parseFloat(dom.style.left) >= cha) {
        return;
      }
      this.imgIndex++;

      let marginRight = parseFloat(
        window.getComputedStyle(
          document.querySelector(".detail-con .main .imgs > div > div > div>a")
        )["margin-right"]
      );
      let width = parseFloat(
        window.getComputedStyle(
          document.querySelector(".detail-con .main .imgs > div > div > div>a")
        )["width"]
      );
      console.log(-this.imgIndex * (width + marginRight));
      dom.style.left = -this.imgIndex * (width + marginRight) + "px";
    },
    collection() {
      // this.$router.push({
      //   name:'Collection'
      // })
      if (!this.token) {
        this.loginShow = true;
      }
    },
  },
};
</script>

<style lang="less">
@media screen and (min-width: 750px) {
  .wrap {
    background: url("~@/assets/pro_detail_bg.png");
    background-size: 100% auto;
    > div {
      width: 1760px;
      margin: 0 auto;
      .pro-detail-wrap {
        padding: 50px 60px 176px;
        background: #fff;
        overflow: hidden;
        .detail-con {
          float: left;
          width: 1290px;
          margin-right: 60px;
          .main {
            overflow: hidden;
            .imgs {
              float: left;
              width: 580px;
              > img {
                width: 580px;
                height: 420px;
                margin-bottom: 20px;
              }
              > div {
                display: flex;
                > a {
                  width: 20px;
                  height: 72px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                > div {
                  overflow: hidden;
                  flex: 1;
                  margin: 0 20px;
                  position: relative;
                  > div {
                    position: absolute;
                    left: 0;
                    white-space: nowrap;
                    font-size: 0;
                    transition: all 0.3s;
                    > a {
                      display: inline-block;
                      width: 100px;
                      height: 72px;
                      margin-right: 19px;
                      img {
                        width: 100%;
                        height: 100%;
                      }
                      box-sizing: border-box;
                    }
                    > a.on {
                      border: 1px solid red;
                    }
                    > a:nth-last-child(1) {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
            .info {
              float: left;
              width: 670px;
              padding: 15px 0 0;
              margin-left: 40px;
              > .name {
                font-size: 32px;
                font-weight: 600;
                color: #333333;
                line-height: 45px;
                margin-bottom: 10px;
                font-family: PingFangSC-Semibold, PingFang SC;
              }
              > .code {
                font-size: 20px;
                color: #f2360d;
                line-height: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
              }
              > .money {
                margin-top: 20px;
                background: url("~@/assets/money_bg.png") 100% 100%;
                padding: 20px 0 20px 15px;
                > p {
                  margin-bottom: 20px;
                  > span {
                    vertical-align: middle;
                  }
                  > .label {
                    font-size: 20px;
                    color: #999999;
                    line-height: 28px;
                    margin-right: 30px;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                  > .now-money {
                    font-size: 34px;
                    font-weight: 600;
                    color: #f2360d;
                    line-height: 48px;
                    margin-right: 10px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                  }
                  > .old-money {
                    font-size: 20px;
                    color: #999999;
                    line-height: 28px;
                    text-decoration: underline;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                  > .c {
                    font-size: 20px;
                    color: #b45f17;
                    line-height: 28px;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                }
                > p:nth-child(1) {
                  > .label {
                    letter-spacing: 8px;
                  }
                }
                > p:nth-last-child(1) {
                  margin-bottom: 0;
                }
              }
              > .others {
                margin-top: 23px;
                > p {
                  margin-bottom: 24px;
                  > span {
                    vertical-align: middle;
                  }
                  > .label {
                    font-size: 20px;
                    color: #999999;
                    line-height: 28px;
                    width: 120px;
                    display: inline-block;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                  .c {
                    font-size: 20px;
                    color: #333333;
                    line-height: 28px;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                }
                > p:nth-last-child(1) {
                  margin-bottom: 0;
                }
              }
              > .btns {
                margin-top: 30px;
                overflow: hidden;
                > a {
                  display: block;
                  box-sizing: border-box;
                  font-family: PingFangSC-Regular, PingFang SC;
                }
                > a:hover {
                  opacity: 0.7;
                }
                > .sc {
                  float: left;
                  width: 310px;
                  background: rgba(228, 57, 60, 0.1);
                  border: 1px solid #e4393c;
                  text-align: center;
                  font-size: 26px;
                  color: #e4393c;
                  line-height: 72px;
                }
                > .tel {
                  float: right;
                  width: 310px;
                  text-align: center;
                  background: #e4393c;
                  font-size: 26px;
                  color: #ffffff;
                  line-height: 72px;
                }
              }
            }
          }
          .h2-title {
            background: #f2f2f2;
            line-height: 68px;
            font-size: 22px;
            color: #666666;
            padding-left: 40px;
            font-family: PingFangSC-Regular, PingFang SC;
          }
          .content-details {
            display: flex;
            > p {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              >img{
                width: 100% !important;
              }
            }
          }
          .params {
            margin-top: 115px;
            > ul {
              overflow: hidden;
              padding-top: 19px;
              > li {
                padding: 0 38px;
                box-sizing: border-box;
                float: left;
                width: 50%;
                border-bottom: 1px dashed #d9d9d9;
                > p {
                  font-size: 22px;
                  color: #666666;
                  line-height: 52px;
                  font-family: PingFangSC-Regular, PingFang SC;
                }
              }
            }
          }
          .content {
            margin-top: 30px;
            > div {
              img {
                max-width: 100%;
              }
            }
          }
        }
        .about-pros {
          float: right;
          width: 230px;
          border-left: 2px solid #f2f2f2;
          padding-left: 58px;
          > .title {
            text-align: center;
            padding: 18px 0;
          }
          > .list {
            > li {
              margin-bottom: 40px;
              > a {
                display: block;
                > img {
                  width: 100%;
                  margin-bottom: 8px;
                }
                > div {
                  > p {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 28px;
                    color: #333;
                    margin-bottom: 3px;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                  > span {
                    font-size: 20px;
                    font-weight: 500;
                    color: #f2360d;
                    line-height: 28px;
                    font-family: PingFangSC-Medium, PingFang SC;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    > div {
      background: #f2f2f2;
      .pro-detail-wrap {
        background: #fff;
        .detail-con {
          .main {
            .imgs {
              > img {
                width: 100%;
                margin-bottom: 13px;
              }
              > div {
                display: flex;
                > a {
                  width: 13px;
                  height: 47px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                > div {
                  overflow: hidden;
                  flex: 1;
                  margin: 0 12px;
                  position: relative;
                  > div {
                    position: absolute;
                    left: 0;
                    white-space: nowrap;
                    font-size: 0;
                    transition: all 0.3s;
                    > a {
                      display: inline-block;
                      width: 65px;
                      height: 47px;
                      margin-right: 12px;
                      img {
                        width: 100%;
                        height: 100%;
                      }
                      box-sizing: border-box;
                    }
                    > a.on {
                      border: 1px solid red;
                    }
                    > a:nth-last-child(1) {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
            .info {
              padding: 32px 13px 0;
              // >{
              //   padding-left: 8px;
              // }
              > .name {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                line-height: 23px;
                margin-bottom: 4px;
                padding: 0 8px;
                font-family: PingFangSC-Semibold, PingFang SC;
              }
              > .code {
                font-size: 12px;
                color: #f2360d;
                line-height: 17px;
                padding: 0 8px;
                font-family: PingFangSC-Regular, PingFang SC;
              }
              > .money {
                margin-top: 9px;
                background: url("~@/assets/money_bg.png") 100% 100%;
                padding: 20px 0 20px 15px;
                > p {
                  margin-bottom: 8px;
                  > span {
                    vertical-align: middle;
                  }
                  > .label {
                    font-size: 12px;
                    color: #999999;
                    line-height: 17px;
                    margin-right: 8px;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                  > .now-money {
                    font-size: 17px;
                    font-weight: 600;
                    color: #f2360d;
                    line-height: 24px;
                    margin-right: 8px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                  }
                  > .old-money {
                    font-size: 11px;
                    color: #999999;
                    line-height: 16px;
                    text-decoration: underline;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                  > .c {
                    font-size: 12px;
                    color: #b45f17;
                    line-height: 17px;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                }
                > p:nth-child(1) {
                  > .label {
                    letter-spacing: 4px;
                  }
                }
                > p:nth-last-child(1) {
                  margin-bottom: 0;
                }
              }
              > .others {
                padding: 0 8px;
                margin-top: 10px;
                > p {
                  margin-bottom: 10px;
                  > span {
                    vertical-align: middle;
                  }
                  > .label {
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 17px;
                    width: 60px;
                    display: inline-block;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                  .c {
                    font-size: 12px;
                    color: #333333;
                    line-height: 17px;
                    font-family: PingFangSC-Regular, PingFang SC;
                  }
                }
                > p:nth-last-child(1) {
                  margin-bottom: 0;
                }
              }
              > .btns {
                margin-top: 24px;
                display: flex;
                font-family: PingFangSC-Regular, PingFang SC;
                > a {
                  display: block;
                  box-sizing: border-box;
                  flex: 1;
                }
                > .sc {
                  float: left;
                  line-height: 38px;
                  background: rgba(228, 57, 60, 0.1);
                  border: 1px solid #e4393c;
                  text-align: center;
                  color: #e4393c;
                  font-size: 14px;
                }
                > .tel {
                  margin-left: 15px;
                  line-height: 38px;
                  background: #e4393c;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 400;
                  color: #ffffff;
                }
              }
            }
          }
          .h2-title {
            background: #f2f2f2;
            line-height: 38px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            padding-left: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
          }
          .params {
            margin-top: 25px;
            > ul {
              > li {
                padding: 0 20px;
                border-bottom: 1px dashed #d9d9d9;
                > p {
                  font-size: 14px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 36px;
                  font-family: PingFangSC-Regular, PingFang SC;
                }
              }
              > li:nth-last-child(1) {
                border-bottom: none;
              }
            }
          }
          .content {
            > div {
              img {
                max-width: 100%;
              }
            }
          }
        }
        .about-pros {
          display: none;
        }
      }
    }
  }
}
</style>