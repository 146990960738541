<template>
  <div
    class="register-wrap"
    :style="{display: show ? 'block' : 'none'}"
    @click="$emit('closeRegister')"
  >
    <div @click.stop>
      <p class="title">立即注册</p>
      <p class="tel">如需帮助，请拨打400-108-3166</p>
      <div class="form">
        <div>
          <div>
            <img src="@/assets/form_name.png">
            <input
              v-model="form.name"
              placeholder="姓名"
              type="text"
            >
          </div>
        </div>

        <div>
          <div>
            <img src="@/assets/form_phone.png">
            <input
              v-model="form.mobile"
              placeholder="手机号"
              type="text"
            >
          </div>
        </div>
        <div>
          <div>
            <img src="@/assets/form_code.png">
            <input
              v-model="form.code"
              placeholder="验证码"
              type="text"
            >
          </div>
          <a
            href="javascript:;"
            :style="{cursor: codeFlag ? 'pointer' : 'not-allowed'}"
            @click="getCode"
          >{{codeFlag ? '获取验证码' : `${codeSeconds}秒`}}</a>
        </div>

        <div>
          <div>
            <img src="@/assets/form_password.png">
            <input
              v-model="form.password"
              placeholder="密码"
              :type="showPassword ? 'text' : 'password'"
            >
            <img
              @click="showPassword = !showPassword"
              :src="showPassword ? openEyeImg : closeEyeImg"
            >
          </div>
        </div>

      </div>
      <a
        class="btn"
        @click="register"
        href="javascript:;"
      >立即注册</a>
      <p class="goto">已有账号？<a
          href="javascript:;"
          @click="$emit('closeRegister');$emit('openLogin');"
        >立即登录</a></p>
      <a
        class="close"
        href="javascript:;"
        @click="$emit('closeRegister')"
      >
        <img src="@/assets/close_tan.png">
      </a>
    </div>
  </div>
</template>

<script>
import { goRegister } from '@/api/user'
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      showPassword: false,
      openEyeImg: require('@/assets/open_eye.png'),
      closeEyeImg: require('@/assets/close_eye.png'),
      codeFlag: true,
      codeSeconds: 5,
      form: {
        mobile: '',
        password: '',
        name: '',
        code: '',
      },
    }
  },
  methods: {
    register() {
      goRegister(this.form)
        .then((res) => {
          if (res.data.code) {
            this.$message.success(res.data.msg)
            this.$emit('closeRegister')
            this.$store.dispatch('user/registerLogin', res.data.data.userinfo.token)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getCode() {
      if (!this.codeFlag) {
        return
      }
      this.codeFlag = false
      const inter = setInterval(() => {
        this.codeSeconds--
        if (this.codeSeconds === 0) {
          this.codeFlag = true
          this.codeSeconds = 60
          clearInterval(inter)
        }
      }, 1000)
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .register-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    width: auto !important;
    > div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      width: 486px;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      box-sizing: border-box;
      padding: 37px 50px 47px;
      > .title {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 37px;
      }
      > .tel {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        margin-top: 6px;
      }
      > .form {
        margin-top: 30px;
        > div {
          font-family: PingFangSC-Regular, PingFang SC;
          margin-bottom: 18px;
          display: flex;
          > div {
            flex: 1;
            box-sizing: border-box;
            border: 1px solid #999;
            padding: 0 17px;
            line-height: 48px;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > img {
              width: 16px;
            }
            > input {
              flex: 1;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              font-size: 15px;
              margin-left: 17px;
            }
          }
          > div:nth-last-child(1) {
            margin-bottom: 0;
            > input {
              margin-right: 17px;
            }
            > img:nth-last-child(1) {
              cursor: pointer;
            }
          }
          > a {
            display: inline-block;
            padding: 0 15px;
            line-height: 48px;
            background: #be955e;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-left: 18px;
          }
        }
        > div:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
      > .btn {
        display: block;
        line-height: 50px;
        background: #be955e;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 18px;
      }
      > .btn:hover {
        opacity: 0.7;
      }
      > .goto {
        margin-top: 20px;
        text-align: right;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        > a {
          color: #be955e;
        }
      }
      > .close {
        position: absolute;
        right: 50px;
        top: 47px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .register-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5) !important;
    > div {
      margin: 0 15px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #fff;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      box-sizing: border-box;
      padding: 27px;
      > .title {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
      > .tel {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        margin-top: 4px;
      }
      > .form {
        margin-top: 20px;
        > div {
          margin-bottom: 11px;
          display: flex;
          > div {
            flex: 1;
            box-sizing: border-box;
            border: 1px solid #999;
            padding: 0 13px;
            line-height: 42px;
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > img {
              width: 13px;
            }
            > input {
              flex: 1;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              font-size: 13px;
              margin-left: 13px;
            }
          }
          > div:nth-last-child(1) {
            margin-bottom: 0;
            > input {
              margin-right: 13px;
            }
            > img:nth-last-child(1) {
              cursor: pointer;
            }
          }
          > a {
            display: inline-block;
            padding: 0 14px;
            line-height: 42px;
            background: #be955e;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-left: 10px;
          }
        }
        > div:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
      > .btn {
        display: block;
        line-height: 42px;
        background: #be955e;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 10px;
      }
      > .goto {
        margin-top: 12px;
        text-align: center;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        > a {
          color: #be955e;
        }
      }
      > .close {
        position: absolute;
        right: 27px;
        top: 34px;
      }
    }
  }
}
</style>