<template>
  <div
    class="login-wrap"
    :style="{display: show ? 'block' : 'none'}"
    @click="$emit('closeLogin')"
  >
    <div @click.stop>
      <p class="title">立即登录</p>
      <p class="tel">如需帮助，请拨打400-108-3166</p>
      <div class="form">
        <div>
          <img src="@/assets/form_phone.png">
          <input
            v-model="form.mobile"
            placeholder="手机号"
            type="text"
          >
        </div>
        <div>
          <img src="@/assets/form_password.png">
          <input
            v-model="form.password"
            placeholder="密码"
            :type="showPassword ? 'text' : 'password'"
          >
          <img
            @click="showPassword = !showPassword"
            :src="showPassword ? openEyeImg : closeEyeImg"
          >
        </div>
      </div>
      <a
        class="btn"
        href="javascript:;"
        @click="login"
      >立即登录</a>
      <p class="goto">还没有账号？<a
          href="javascript:;"
          @click="$emit('closeLogin');$emit('openRegister')"
        >立即注册</a></p>
      <a
        class="close"
        href="javascript:;"
        @click="$emit('closeLogin')"
      >
        <img src="@/assets/close_tan.png">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      showPassword: false,
      openEyeImg: require('@/assets/open_eye.png'),
      closeEyeImg: require('@/assets/close_eye.png'),
      form: {
        mobile: '',
        password: '',
      },
    }
  },
  methods: {
    login() {
      this.$store
        .dispatch('user/login', this.form)
        .then((res) => {
          this.$message.success(res)
          this.$emit('closeLogin')
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .login-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    width: auto !important;
    > div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      width: 486px;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      box-sizing: border-box;
      padding: 37px 50px 47px;
      > .title {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 37px;
      }
      > .tel {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        margin-top: 6px;
      }
      > .form {
        margin-top: 30px;
        > div {
          font-family: PingFangSC-Regular, PingFang SC;
          box-sizing: border-box;
          border: 1px solid #999;
          padding: 0 17px;
          line-height: 48px;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 18px;
          > img {
            width: 16px;
          }
          > input {
            flex: 1;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            margin-left: 17px;
          }
        }
        > div:nth-last-child(1) {
          margin-bottom: 0;
          > input {
            margin-right: 17px;
          }
          > img:nth-last-child(1) {
            cursor: pointer;
          }
        }
      }
      > .btn {
        display: block;
        line-height: 50px;
        background: #be955e;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 18px;
      }
      > .btn:hover {
        opacity: 0.7;
      }
      > .goto {
        margin-top: 20px;
        text-align: right;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        > a {
          color: #be955e;
        }
      }
      > .close {
        position: absolute;
        right: 50px;
        top: 47px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .login-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5) !important;
    > div {
      margin: 0 15px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #fff;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      box-sizing: border-box;
      padding: 27px;
      > .title {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
      > .tel {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        margin-top: 4px;
      }
      > .form {
        margin-top: 20px;
        > div {
          box-sizing: border-box;
          border: 1px solid #999;
          padding: 0 5px;
          line-height: 42px;
          height: 44px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 11px;
          > img {
            width: 13px;
          }
          > input {
            flex: 1;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-left: 13px;
          }
        }
        > div:nth-last-child(1) {
          margin-bottom: 0;
          > input {
            margin-right: 13px;
          }
          > img:nth-last-child(1) {
            cursor: pointer;
          }
        }
      }
      > .btn {
        display: block;
        line-height: 42px;
        background: #be955e;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 10px;
      }
      > .goto {
        margin-top: 12px;
        text-align: center;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        > a {
          color: #be955e;
        }
      }
      > .close {
        position: absolute;
        right: 27px;
        top: 34px;
      }
    }
  }
}
</style>